import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
  checkout,
} from "../redux/cartSlice";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const [loading, setLoading] = useState(false); // Loading state

  const handleRemoveItem = (item) => {
    dispatch(removeFromCart(item));
    toast.success("Service Removed From The Cart");
  };

  const handleIncreaseQuantity = (item) => {
    dispatch(increaseQuantity(item));
  };

  const handleDecreaseQuantity = (item) => {
    dispatch(decreaseQuantity(item));
  };

  const handleCheckout = async () => {
    setLoading(true); // Start loading
    const stripe = await loadStripe(
      "pk_live_51QRFSaLftbb53CIgJ2cIjD6lP7Moj8cc3P7tMFilK3IXzl4En4nqN8USCiDKXSlYnqb9ddCzo68FAoQ4bMxiiaME00xKZ7lerG"
    );

    const body = {
      products: cartItems,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        "https://book-stores-7e1n.onrender.com/create-checkout-session",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const session = await response.json();

      const orderDetails = {
        orderId: session.id,
        amount: session.amount_total,
        date: new Date().toISOString(),
        status: "paid",
      };

      const cartDetails = cartItems.map((item) => ({
        productId: item.id,
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      }));

      const orderWithProducts = {
        ...orderDetails,
        products: cartDetails,
      };

      const existingOrders = JSON.parse(localStorage.getItem("orders")) || [];
      existingOrders.push(orderWithProducts);
      localStorage.setItem("orders", JSON.stringify(existingOrders));

      dispatch(checkout());
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.log(result.error);
      } else {
        console.log("Payment successful, session details:", session);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const totalAmount = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Your Cart
      </h1>
      <div>
        {cartItems.length === 0 ? (
          <p className="text-center text-gray-600">Your cart is empty</p>
        ) : (
          <ul>
            {cartItems.map((item) => (
              <li key={item.id} className="mb-4">
                <div className="flex justify-between items-center">
                  <div>
                    <h2 className="text-xl font-semibold">{item.name}</h2>
                    <p className="text-lg">
                      Price: ${item.price * item.quantity}
                    </p>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => handleDecreaseQuantity(item)}
                        className="px-2 py-1 bg-teal-500 text-white rounded-md"
                      >
                        -
                      </button>
                      <span className="text-lg">{item.quantity}</span>
                      <button
                        onClick={() => handleIncreaseQuantity(item)}
                        className="px-2 py-1 bg-teal-500 text-white rounded-md"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => handleRemoveItem(item)}
                      className="text-red-500 font-semibold"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Total Amount */}
      {cartItems.length > 0 && (
        <div className="mt-8 flex justify-between text-lg font-semibold">
          <p>Total Amount:</p>
          <p>${totalAmount}</p>
        </div>
      )}

      {/* Checkout Button */}
      {cartItems.length > 0 && (
        <div className="mt-8 flex justify-center">
          <button
            onClick={handleCheckout}
            className={`px-6 py-2 font-semibold rounded-md ${
              loading
                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                : "bg-teal-500 text-white"
            }`}
            disabled={loading} // Disable button when loading
          >
            {loading ? "Processing..." : "Checkout"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Cart;
