import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Adjust path if necessary
import { toast } from "react-toastify";

const SubService2 = () => {
  const dispatch = useDispatch();

  // Add to Cart Function
  const handleAddToCart = (id, name, price) => {
    dispatch(addToCart({ id, name, price }));
    toast.success(`${name} added to the cart successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Header Section */}
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Cover Design: Make a Lasting First Impression
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        In the world of books, judging by the cover isn't just allowed—it's
        expected. Our professional cover design service ensures your book stands
        out in a crowded marketplace.
      </p>

      {/* Service Plans Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {/* Two Cover Formats Plan */}
        <div className="bg-yellow-400 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Two cover formats
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$695</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Choose from paperback, hardback, or e-book</li>
          </ul>
          <button
            aria-label="Add Two cover formats to cart"
            onClick={() => handleAddToCart(3, "Two cover formats", 695)}
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart
          </button>
        </div>

        {/* All Three Cover Formats Plan */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            All three cover formats
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$895</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Paperback</li>
            <li>Hardback</li>
            <li>E-book</li>
          </ul>
          <button
            aria-label="Add All three cover formats to cart"
            onClick={() => handleAddToCart(4, "All three cover formats", 895)}
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart
          </button>
        </div>
      </div>

      {/* Additional Info Section */}
      <div className="mt-16">
        <p className="text-gray-600 text-lg leading-relaxed">
          Our designers blend artistry with marketing savvy to create covers
          that not only look stunning but also resonate with your target
          audience. Don't let your book's potential be hidden behind a
          lackluster cover—invest in a design that demands attention.
        </p>
      </div>
    </div>
  );
};

export default SubService2;
