import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Adjust path if necessary
import { toast } from "react-toastify";

const SubService5 = () => {
  const dispatch = useDispatch();
  const [wordCount, setWordCount] = useState(""); // State to track word count input

  // Handle word count input
  const handleWordCountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setWordCount(value);
    }
  };

  // Calculate price based on word count
  const calculatePrice = (ratePerWord) => {
    return wordCount ? (wordCount * ratePerWord).toFixed(2) : 0;
  };

  // Add to cart with dynamic pricing
  const handleAddToCart = (id, name, price) => {
    if (wordCount === "" || wordCount <= 0) {
      toast.error("Please enter a valid word count.");
      return;
    }
    dispatch(addToCart({ id, name, price }));
    toast.success(`${name} added to the cart successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Header Section */}
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Editing: Polishing Your Prose to Perfection
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        Our editing services cater to fiction, nonfiction, and academic works,
        ensuring your manuscript shines in its best light.
      </p>

      {/* Word Count Input Section */}
      <div className="mb-10">
        <label
          htmlFor="wordCount"
          className="block text-lg font-semibold text-gray-800 mb-2"
        >
          Enter Word Count:
        </label>
        <input
          type="number"
          id="wordCount"
          value={wordCount}
          onChange={handleWordCountChange}
          className="w-full p-3 border border-gray-300 rounded-md"
          placeholder="Enter the number of words in your manuscript"
        />
      </div>

      {/* Service Plans Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {/* Manuscript Critique */}
        <div className="bg-yellow-400 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Manuscript Critique
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$495</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Comprehensive critique of your manuscript</li>
          </ul>
          <button
            onClick={() => handleAddToCart(10, "Manuscript Critique", 495)}
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart
          </button>
        </div>

        {/* Line Editing */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Line Editing
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">
            $0.06 per word
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Refines grammar, style, and flow</li>
          </ul>
          <button
            onClick={() =>
              handleAddToCart(
                11,
                `Line Editing (${wordCount} words)`,
                calculatePrice(0.06)
              )
            }
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart (${calculatePrice(0.06)})
          </button>
        </div>

        {/* Developmental Editing */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Developmental Editing
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">
            $0.10 per word
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Focuses on structure, content, and story development</li>
          </ul>
          <button
            onClick={() =>
              handleAddToCart(
                12,
                `Developmental Editing (${wordCount} words)`,
                calculatePrice(0.1)
              )
            }
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart (${calculatePrice(0.1)})
          </button>
        </div>
      </div>

      {/* Additional Info Section */}
      <div className="mt-16">
        <p className="text-gray-600 text-lg leading-relaxed">
          Our team of experienced editors doesn't just correct; they elevate. We
          work closely with you to refine your voice, strengthen your narrative,
          and ensure your message resonates with readers.
        </p>
      </div>
    </div>
  );
};

export default SubService5;
