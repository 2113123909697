import React, { useEffect, useState } from "react";

const MyOrder = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const storedOrders = localStorage.getItem("orders");
    if (storedOrders) {
      try {
        const parsedOrders = JSON.parse(storedOrders);
        setOrders(parsedOrders); // Directly setting the orders array
      } catch (error) {
        console.error("Error parsing stored orders:", error);
        setOrders([]);
      }
    } else {
      setOrders([]); // No orders found in localStorage
    }
  }, []);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Your Orders
      </h1>
      {orders.length === 0 ? (
        <p className="text-center text-gray-600">You have no orders yet.</p>
      ) : (
        orders.map((order, index) => (
          <div key={order.orderId} className="mb-8 border-b pb-4">
            <h2 className="text-2xl font-semibold mb-4">Order #{index + 1}</h2>
            <p>
              <strong>Order ID:</strong> {order.orderId}
            </p>
            <p>
              <strong>Date:</strong> {new Date(order.date).toLocaleString()}
            </p>
            <div>
              {order.products.map((item) => (
                <div key={item.productId} className="mb-4">
                  <h3 className="text-lg font-semibold">{item.name}</h3>
                  <p>Price: ${item.price * item.quantity}</p>
                  <p>Quantity: {item.quantity}</p>
                </div>
              ))}
            </div>
            <div className="flex justify-between text-lg font-semibold"></div>
            {/* <div className="mt-2 text-green-500">
              Payment Status: {order.status}
            </div> */}
          </div>
        ))
      )}
    </div>
  );
};

export default MyOrder;
