import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Adjust path if necessary
import { toast } from "react-toastify";

const SubService3 = () => {
  const dispatch = useDispatch();

  // Add to Cart Function
  const handleAddToCart = (id, name, price) => {
    dispatch(addToCart({ id, name, price }));
    toast.success(`${name} added to the cart successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Header Section */}
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Interior Formatting: Beauty Beyond the Cover
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        A well-formatted interior is the mark of a truly professional
        publication. Our interior formatting service ensures your book looks as
        good on the inside as it does on the outside.
      </p>

      {/* Service Plans Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {/* Print and E-Book Formatting Plan */}
        <div className="bg-yellow-400 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Print and E-Book Formatting
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$595</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Includes 3 images</li>
          </ul>
          <button
            aria-label="Add Print and E-Book Formatting to cart"
            onClick={() =>
              handleAddToCart(5, "Print and E-Book Formatting", 595)
            }
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add To Cart
          </button>
        </div>

        {/* Additional Images Plan */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Additional Images
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">
            $150 (for 10 extra images)
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Additional images in blocks of 10</li>
          </ul>
          <button
            aria-label="Add Additional Images to cart"
            onClick={() =>
              handleAddToCart(6, "Additional Images (10 extra)", 150)
            }
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add To Cart
          </button>
        </div>
      </div>

      {/* Additional Info Section */}
      <div className="mt-16">
        <p className="text-gray-600 text-lg leading-relaxed">
          We meticulously craft each page to enhance readability and reflect the
          tone of your work. From font selection to layout design, we ensure
          your book's interior is a seamless extension of your narrative.
        </p>
      </div>
    </div>
  );
};

export default SubService3;
