import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Adjust path if necessary
import { toast } from "react-toastify";

const SubService8 = () => {
  const dispatch = useDispatch();
  const [wordCount, setWordCount] = useState(""); // State to track word count input

  // Handle word count input
  const handleWordCountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setWordCount(value);
    }
  };

  // Calculate price based on word count
  const calculatePrice = (ratePerWord) => {
    const minWordCount = 150; // Minimum word count
    const totalWords = Math.max(wordCount || 0, minWordCount); // Ensure minimum word count is used
    return (totalWords * ratePerWord).toFixed(2); // Calculate price based on total words
  };

  // Add to cart with dynamic pricing
  const handleAddToCart = (id, name, ratePerWord) => {
    const minWordCount = 150;
    const finalWordCount = Math.max(wordCount || 0, minWordCount);
    const price = calculatePrice(ratePerWord); // Get the price value here

    // Add item to cart
    dispatch(
      addToCart({
        id,
        name: `${name} (${finalWordCount} words)`,
        price: parseFloat(price), // Ensure price is a number
      })
    );
    toast.success(`${name} added to the cart successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Header Section */}
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Audiobook Production: Give Voice to Your Words
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        In today's fast-paced world, audiobooks are more popular than ever. Our
        audiobook production service brings your story to life with professional
        narration and high-quality audio.
      </p>

      {/* Word Count Input Section */}
      <div className="mb-10">
        <label
          htmlFor="wordCount"
          className="block text-lg font-semibold text-gray-800 mb-2"
        >
          Enter Word Count (Minimum 150 words):
        </label>
        <input
          type="number"
          id="wordCount"
          value={wordCount}
          onChange={handleWordCountChange}
          className="w-full p-3 border border-gray-300 rounded-md"
          placeholder="Enter the number of words in your manuscript"
        />
      </div>

      {/* Service Plans Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {/* Professional Narration */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Professional Narration
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">
            $0.05 per word (Min $150)
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>High-quality audio narration</li>
          </ul>
          <button
            onClick={() =>
              handleAddToCart(
                17,
                "Professional Narration",
                0.05 // Passing the rate per word
              )
            }
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart (${calculatePrice(0.05)})
          </button>
        </div>

        {/* Sound Effects */}
        <div className="bg-yellow-400 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Sound Effects
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">
            Custom Quote
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Immersive soundscapes and effects</li>
          </ul>
          <button className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold">
            Request Quote
          </button>
        </div>

        {/* Multiple Narrators */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Multiple Narrators
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">
            Custom Quote
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Voices tailored for each character</li>
          </ul>
          <button className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold">
            Request Quote
          </button>
        </div>
      </div>

      {/* Additional Info Section */}
      <div className="mt-16">
        <p className="text-gray-600 text-lg leading-relaxed">
          We use only the highest-rated, industry-quality voice actors, offering
          a wide range of voices to suit every mood, genre, and effect. Our
          state-of-the-art recording and editing process ensures a polished,
          professional product that will captivate listeners.
        </p>
      </div>
    </div>
  );
};

export default SubService8;
