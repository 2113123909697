import React from "react";

const Resources = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold text-center mb-6">Resources</h1>
      <p className="text-lg font-bold text-gray-800">
        Welcome to our Author Resources Hub! This page is dedicated to providing
        valuable tools and materials for writers at all stages of their careers.
        Whether you're just starting out or you're a seasoned author, we're
        committed to supporting your literary journey.
      </p>
      <p className="text-lg font-bold text-gray-800 mt-4">
        We're constantly updating this space with new resources designed to help
        you achieve success in your writing career. From writing tips and
        publishing guides to marketing strategies and industry insights, we aim
        to offer a comprehensive collection of aids for aspiring and established
        authors alike.
      </p>
      <p className="text-lg font-bold text-gray-800 mt-4">
        Please check back regularly as we continue to add fresh, helpful
        content. Our goal is to empower you with the knowledge and tools you
        need to thrive in the world of authorship. Happy writing!
      </p>
    </div>
  );
};

export default Resources;
