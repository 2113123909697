import React, { useState, useRef, useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

export const jobPostings = [
  {
    title: "What is BookBoost?",
    details:
      "BookBoost is a premier book publishing services company dedicated to empowering authors, speakers, coaches, and thought leaders to achieve bestseller status and amplify their impact. Our team of seasoned publishing professionals has over 100 years of combined experience working at the heart of the industry, and we leverage this expertise to guide our clients through every step of the publishing process.",
  },
  {
    title: "What makes BookBoost different?",
    details:
      "At BookBoost, we're not just a service provider – we're a strategic partner committed to your long-term success. Unlike traditional publishing houses, we've built our business around agility, transparency, and a results-driven approach. We're so confident in our ability to deliver that we offer a bold guarantee: if we fail to meet our promises, you don't pay.",
  },
  {
    title: "Who is on the BookBoost team?",
    details:
      "Our team is comprised of publishing veterans who have honed their skills at the Big Five publishing houses, as well as founders of successful independent publishing ventures. This unique blend of experience and expertise allows us to provide our clients with unparalleled guidance and support.",
  },
  {
    title: "What is your company's mission?",
    details:
      "BookBoost's mission is to empower authors, speakers, coaches, and thought leaders to achieve the recognition and impact they deserve. We believe that exceptional writing and ideas deserve exceptional opportunities, and we're dedicated to helping our clients navigate the complexities of modern publishing to reach their full potential.",
  },
  {
    title:
      "Do you work with self-published authors, or only traditional publishers?",
    details:
      "At BookBoost, we are specially designed to build self-published authors to the levels only achievable by traditionally published authors of the past. We welcome authors from all publishing backgrounds, whether you're self-published, traditionally published, or somewhere in between. Our services are tailored to support authors at every stage of their literary journey.",
  },
  {
    title: "How does the Bestseller Service work?",
    details:
      "Our Bestseller Service is designed to propel your book to the top of bestseller lists through a strategic, end-to-end campaign. We handle every aspect of the process, from distribution and optimization to comprehensive marketing strategies, to ensure your work reaches the right readers and secures its spot on the charts.",
  },
  {
    title:
      "How long does it typically take to see results from your Bestseller Service?",
    details:
      "Because of the nature of how a bestseller is calculated, we must complete the process in quick time. We are often able to take a book from submission to bestseller within a month to six weeks, but we have also seen some take even shorter while other books may take a bit longer.",
  },
  {
    title:
      "What payment options are available, and do you offer payment plans?",
    details:
      "We strive to make our services accessible to authors of all backgrounds, which is why we offer flexible payment options. You can pay for our services online using major credit cards like Visa and Mastercard. We also provide the option to spread out your payments through our convenient payment plan.",
  },
  {
    title: "What guarantees do you offer with the Bestseller Service?",
    details:
      "We're so confident in our ability to deliver results that we offer a bold guarantee with our Bestseller Service packages. If we fail to meet the specific sales targets outlined in our Bestseller Gold or Platinum offerings, you won't pay a dime.",
  },
  {
    title: "Do you offer any additional support or resources for authors?",
    details:
      "Absolutely. In addition to our core publishing services, we provide a wealth of resources for authors, including educational content, webinars, and one-on-one consultations. Our goal is to be a comprehensive partner in your literary success, offering guidance and support at every stage of your journey.",
  },
];

const JobPosting = ({ job, isOpen, onClick }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState("0px");

  useEffect(() => {
    setContentHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
  }, [isOpen]);

  return (
    <div className="border border-gray-300 rounded-lg shadow-md mb-4">
      <div
        className="flex justify-between items-center p-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition-colors duration-300"
        onClick={onClick}
      >
        <h3 className="text-lg font-semibold text-gray-800">{job.title}</h3>
        <span className="text-gray-600">
          {isOpen ? <FaMinus /> : <FaPlus />}
        </span>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: contentHeight }}
        className="overflow-hidden transition-all duration-500 ease-in-out"
      >
        <div className="p-4 bg-white text-gray-700">
          <p className="whitespace-pre-line">{job.details}</p>
        </div>
      </div>
    </div>
  );
};

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white text-gray-800">
      <div className="max-w-4xl mx-auto p-6">
        <p className="font-bold text-3xl text-center text-gray-800">
          Frequently <span className="text-blue-500">Asked</span> Questions
        </p>
        <div className="w-16 h-1 mx-auto bg-blue-500 mt-2 mb-6"></div>
        {jobPostings.map((job, index) => (
          <JobPosting
            key={index}
            job={job}
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
