import React from "react";

const Privacy = () => {
  return (
    <div className=" text-gray-800 py-10">
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Privacy Policy and Terms of Service
        </h1>

        {/* Privacy Policy */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-600 mb-4">
            Privacy Policy
          </h2>
          <ol className="list-decimal list-inside space-y-4 text-gray-700">
            <li>
              <strong>Information Collection:</strong> We collect personal
              information including names, email addresses, and payment details
              when you use our services.
            </li>
            <li>
              <strong>Use of Information:</strong> Your information is used to
              provide and improve our services, process payments, and
              communicate with you about your account and our services.
            </li>
            <li>
              <strong>Cookies:</strong> We use standard cookies and similar
              tracking technologies to enhance user experience and analyze
              website traffic.
            </li>
            <li>
              <strong>Information Sharing:</strong> We do not share your
              personal information with third parties.
            </li>
            <li>
              <strong>Data Security:</strong> We implement industry-standard
              security measures to protect your personal information.
            </li>
            <li>
              <strong>Data Retention:</strong> We retain your information for as
              long as necessary to provide our services and comply with legal
              obligations.
            </li>
            <li>
              <strong>Your Rights:</strong> You have the right to access,
              correct, or delete your personal information. Contact us for any
              such requests.
            </li>
            <li>
              <strong>Changes to Privacy Policy:</strong> We may update this
              policy periodically. Check back for any changes.
            </li>
            <li>
              <strong>Contact:</strong> For privacy-related inquiries, contact
              [insert contact information].
            </li>
          </ol>
        </section>

        {/* Terms of Service */}
        <section>
          <h2 className="text-2xl font-semibold text-blue-600 mb-4">
            Terms of Service
          </h2>
          <ol className="list-decimal list-inside space-y-4 text-gray-700">
            <li>
              <strong>Services:</strong> BookBoost provides book marketing,
              sales strategies, and author branding services.
            </li>
            <li>
              <strong>User Responsibilities:</strong> Users must provide
              accurate information and comply with these terms.
            </li>
            <li>
              <strong>Intellectual Property:</strong> Users retain rights to
              their content. BookBoost retains rights to its marketing materials
              and strategies.
            </li>
            <li>
              <strong>Payment and Refunds:</strong> Payment is required for
              services. Refunds are provided if the book doesn't reach the
              bestseller list as guaranteed.
            </li>
            <li>
              <strong>Limitation of Liability:</strong> BookBoost is not liable
              for indirect, consequential, or incidental damages.
            </li>
            <li>
              <strong>Termination:</strong> We reserve the right to terminate
              services for violation of these terms.
            </li>
            <li>
              <strong>Governing Law:</strong> These terms are governed by the
              laws of Wyoming, USA.
            </li>
            <li>
              <strong>Changes to Terms:</strong> We may update these terms
              periodically. Continued use of our services constitutes acceptance
              of any changes.
            </li>
            <li>
              <strong>Contact:</strong> For inquiries regarding these terms,
              contact info@getbookboost.com.
            </li>
          </ol>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
