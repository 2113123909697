import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Adjust the path if necessary
import { toast } from "react-toastify";

const SubService9 = () => {
  const dispatch = useDispatch();

  // Add to Cart Handler
  const handleAddToCart = (id, name, price) => {
    dispatch(addToCart({ id, name, price }));
    toast.success(`${name} added to the cart successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Header Section */}
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Author Branding: The Foundation of a Thriving Literary Career
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        In today's interconnected world, your author brand is more than just a
        marketing tool—it's the cornerstone of your professional identity. For
        aspiring career authors, speakers, coaches, and consultants, a
        well-crafted brand is not just beneficial; it's essential.
      </p>

      {/* Package Section */}
      <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">
          Author Branding Package
        </h2>
        <p className="text-xl font-semibold text-gray-800 mb-4">
          $14,995 (payment plan available)
        </p>
        <ul className="list-disc pl-5 text-gray-600">
          <li>Professional website design</li>
          <li>Custom logo creation</li>
          <li>Brand color palette development</li>
          <li>Intensive brand training sessions</li>
          <li>Crafting your unique value proposition</li>
          <li>Brand story creation</li>
          <li>Comprehensive SWOT analysis</li>
          <li>Defining your brand voice</li>
          <li>Establishing your brand identity</li>
          <li>Articulating your brand promise</li>
          <li>Identifying core brand values</li>
          <li>Brand targeting strategies</li>
          <li>Brand positioning in the literary market</li>
        </ul>
        <button
          onClick={() => handleAddToCart(20, "Author Branding Package", 14995)}
          className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
        >
          Add to Cart
        </button>
      </div>

      {/* Additional Info Section */}
      <div className="mt-16">
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          The Power of Professional Branding
        </h3>
        <p className="text-gray-600 text-lg leading-relaxed mb-6">
          A strong author brand sets you apart in a crowded marketplace. It's
          not just about selling books; it's about creating a lasting connection
          with your audience. Here's why it matters:
        </p>
        <ul className="list-disc pl-5 text-gray-600">
          <li>
            <strong>Credibility and Trust:</strong> A professional brand lends
            authority to your work and builds trust with readers and clients.
          </li>
          <li>
            <strong>Consistency Across Platforms:</strong> Ensures a cohesive
            experience for your audience.
          </li>
          <li>
            <strong>Career Longevity:</strong> Helps you weather industry
            changes while maintaining a strong professional identity.
          </li>
          <li>
            <strong>Diversified Income Streams:</strong> Opens opportunities
            beyond book sales, such as speaking engagements and consulting work.
          </li>
          <li>
            <strong>Audience Connection:</strong> Attracts and retains your
            ideal readers by communicating your values and expertise.
          </li>
        </ul>

        <p className="text-gray-600 text-lg leading-relaxed mt-6">
          Without a strong brand, authors and speakers often struggle to stand
          out, attract media attention, or build a loyal following. Let us help
          you build a brand that creates opportunities and sets the stage for a
          thriving, multi-faceted career in the literary world and beyond.
        </p>
      </div>

      {/* Footer Section */}
      <div className="mt-16">
        <p className="text-gray-600 text-lg leading-relaxed">
          At BookBoost, we're committed to helping authors succeed in every
          aspect of their publishing journey. From concept to bestseller status,
          we provide industry expertise and a passion for storytelling to help
          you achieve your literary ambitions. Contact us today for a
          personalized consultation. Your success story starts here.
        </p>
      </div>
    </div>
  );
};

export default SubService9;
