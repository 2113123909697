import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-white text-black py-8 shadow-md border-t-2 border-black">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1: About */}
          <div>
            <h3 className="text-xl font-semibold mb-4">About BookBoost</h3>
            <p className="text-gray-600">
              BookBoost is your one-stop destination for finding the best books
              and empowering authors to reach their audience. Join us in making
              literary dreams come true.
            </p>
          </div>

          {/* Column 2: Quick Links */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul className="grid grid-cols-2 gap-2">
              {[
                { path: "/", label: "Home" },
                { path: "/faq", label: "FAQ" },
                { path: "/services", label: "Services" },
                { path: "/privacy-policy", label: "Privacy Policy" },
                { path: "/testimonials", label: "Testimonials" },
                { path: "/resource", label: "Resource" },
              ].map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.path}
                    className="text-gray-600 hover:text-black transition-colors"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Column 3: Social Media */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              {[
                { href: "https://facebook.com", icon: <FaFacebookF /> },
                { href: "https://instagram.com", icon: <FaInstagram /> },
                { href: "https://twitter.com", icon: <FaTwitter /> },
                { href: "https://linkedin.com", icon: <FaLinkedin /> },
              ].map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-black transition-colors"
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-300 pt-4 text-center text-gray-600">
          &copy; 2024 Book Store. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
