import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes, FaShoppingCart } from "react-icons/fa"; // Import FaShoppingCart for the cart icon
import { useSelector } from "react-redux"; // Import useSelector to access the Redux store
import logo from "../assets/logo.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Access the cart state to get the items
  const cartItems = useSelector((state) => state.cart.items);

  // Calculate the total number of items in the cart
  const totalItemsCount = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-white border-bottom sticky-top shadow-sm px-3 px-md-4 mb-3">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        {/* Logo */}
        <img
          src={logo}
          className="lg:w-44 lg:h-24 w-36 h-20 object-cover"
          alt="not found"
        />

        {/* Hamburger Icon */}
        <button
          className="text-2xl md:hidden"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Navigation Links */}
        <nav
          className={`${
            isMenuOpen ? "block" : "hidden"
          } md:flex md:items-center z-50 md:space-x-4 absolute md:static top-16 left-0 w-full md:w-auto bg-white shadow-md md:shadow-none`}
        >
          <Link className="block py-2 px-4 text-dark md:inline" to="/">
            Home
          </Link>

          <Link className="block py-2 px-4 text-dark md:inline" to="/about">
            About
          </Link>

          <Link className="block py-2 px-4 text-dark md:inline" to="/services">
            Services
          </Link>
          <Link className="block py-2 px-4 text-dark md:inline" to="/orders">
            My Order
          </Link>

          {/* Cart Icon with Item Count */}
          <Link
            className="block py-2 px-4 text-dark md:inline relative"
            to="/cart"
          >
            <FaShoppingCart size={24} />
            {totalItemsCount > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center transform translate-x-1/2 -translate-y-1/2">
                {totalItemsCount}
              </span>
            )}
          </Link>

          <Link className="block py-2 px-4 text-dark md:inline" to="/faq">
            Faq
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
