import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    const storedOrders = localStorage.getItem("orders");
    if (storedOrders) {
      try {
        const parsedOrders = JSON.parse(storedOrders);
        // Assuming we want to display the most recent order
        const latestOrder = parsedOrders[parsedOrders.length - 1];
        setOrderDetails(latestOrder);
      } catch (error) {
        console.error("Error parsing stored orders:", error);
      }
    }
  }, []);

  if (!orderDetails) {
    return (
      <div className="text-center">
        <h1 className="text-3xl font-semibold text-gray-800">
          Order not found!
        </h1>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-semibold text-center mb-10 text-green-600">
        Payment Successful!
      </h1>
      <div className="mb-8 border-b pb-4">
        <h2 className="text-2xl font-semibold mb-4">Order Details</h2>
        <p>
          <strong>Order ID:</strong> {orderDetails.orderId}
        </p>
        <p>
          <strong>Date:</strong> {new Date(orderDetails.date).toLocaleString()}
        </p>
        <div>
          {orderDetails.products.map((item) => (
            <div key={item.productId} className="mb-4">
              <h3 className="text-lg font-semibold">{item.name}</h3>
              <p>Price: ${item.price * item.quantity}</p>
              <p>Quantity: {item.quantity}</p>
            </div>
          ))}
        </div>
        <div className="flex justify-between text-lg font-semibold">
          <p>Total Amount:</p>
          <p>${orderDetails.amount}</p>
        </div>
        <div className="mt-2 text-green-500">
          Payment Status: {orderDetails.status}
        </div>
      </div>
      <div className="text-center mt-8">
        <Link to={"/"} className="px-6 py-3 bg-blue-600 text-white rounded-md">
          Go to Homepage
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;
