// src/redux/cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Helper function to load cart from localStorage
const loadCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
};

const initialState = {
    items: loadCartFromLocalStorage(),
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const item = action.payload;
            const existingItem = state.items.find((i) => i.id === item.id);

            if (existingItem) {
                existingItem.quantity += 1; // Increment quantity if already in cart
            } else {
                state.items.push({ ...item, quantity: 1 }); // Add new item
            }

            // Save to localStorage
            localStorage.setItem("cart", JSON.stringify(state.items));
        },
        removeFromCart: (state, action) => {
            state.items = state.items.filter((item) => item.id !== action.payload.id);
            localStorage.setItem("cart", JSON.stringify(state.items));
        },
        increaseQuantity: (state, action) => {
            const item = state.items.find((i) => i.id === action.payload.id);
            if (item) item.quantity += 1;
            localStorage.setItem("cart", JSON.stringify(state.items));
        },
        decreaseQuantity: (state, action) => {
            const item = state.items.find((i) => i.id === action.payload.id);
            if (item && item.quantity > 1) item.quantity -= 1;
            localStorage.setItem("cart", JSON.stringify(state.items));
        },
        checkout: (state) => {
            // Checkout logic could involve clearing the cart
            state.items = [];
            localStorage.setItem("cart", JSON.stringify(state.items));
        },
    },
});

export const { addToCart, removeFromCart, increaseQuantity, decreaseQuantity, checkout } = cartSlice.actions;
export default cartSlice.reducer;
