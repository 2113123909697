import React from "react";
import { mainservice } from "../data";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirecting

const ServicePage = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleLearnMore = (id) => {
    // Navigate to the service details page by ID
    navigate(`/services/${id}`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Our Services
      </h1>

      {/* Service Rows */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {mainservice.map((service) => (
          <div
            key={service.id}
            className="flex flex-col transform hover:scale-105 transition-transform duration-300 rounded-lg shadow-lg overflow-hidden bg-white hover:bg-gradient-to-r hover:from-teal-500 hover:to-cyan-400"
          >
            <div className="p-6 flex-1">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                {service.title}
              </h2>
              <p className="text-gray-600 text-base mb-6">
                {service.description}
              </p>
            </div>
            {/* Hover Effect and Button */}
            <button
              onClick={() => handleLearnMore(service.id)} // Redirect to service details page
              className="p-4 bg-teal-500 text-white text-center font-semibold uppercase tracking-wider transform transition-transform duration-300 hover:scale-110"
            >
              Learn More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicePage;
