import React from "react";

const testimonials = [
  {
    name: "Sarah Thompson",
    role: "Self-Help Author",
    feedback:
      "Mark and his team transformed my publishing journey. Their expertise in author branding and marketing helped my self-help book reach Amazon's Top 10. I'm forever grateful for their support.",
  },
  {
    name: "Michael Chen",
    role: "Sci-Fi Novelist",
    feedback:
      "As a first-time author, I was overwhelmed by the publishing process. Jessica guided me through every step, from manuscript editing to marketing. My debut novel hit Barnes & Noble's bestseller list!",
  },
  {
    name: "Dr. Emily Rodriguez",
    role: "Medical Non-Fiction",
    feedback:
      "The team's sales strategies were crucial in getting my medical research book noticed. They helped me secure speaking engagements and media appearances, amplifying my message beyond expectations.",
  },
  {
    name: "Jake Williams",
    role: "Business Coach",
    feedback:
      "I've worked with other marketing firms before, but none compare to Mark's team. Their author branding services helped establish me as an authority in my field, boosting both book sales and my coaching business.",
  },
  {
    name: "Olivia Parker",
    role: "Young Adult Fiction",
    feedback:
      "Dani understood my target audience perfectly. Her tailored marketing approach helped my YA novel gain traction on social media, leading to a surge in Amazon sales and a devoted fan base.",
  },
  {
    name: "Robert Greenfield",
    role: "Historical Non-Fiction",
    feedback:
      "I was skeptical at first, but Mark's team proved me wrong. Their comprehensive approach to book promotion resulted in my history book reaching #1 in its category on Amazon.",
  },
  {
    name: "Amelia Foster",
    role: "Cookbook Author",
    feedback:
      "Jessica's innovative sales strategies helped my cookbook stand out in a crowded market. Her efforts led to my book becoming a Barnes & Noble bestseller and opened up new opportunities for me.",
  },
  {
    name: "Thomas Nguyen",
    role: "Motivational Speaker",
    feedback:
      "Working with this team was a game-changer for my career. Their author branding services established me as a thought leader, boosting both my Amazon book sales and speaking engagements.",
  },
  {
    name: "Lisa Morales",
    role: "Romance Novelist",
    feedback:
      "I was struggling to gain traction with my romance series until I partnered with Dani. Her targeted campaigns helped me build a loyal readership, and my latest novel hit Amazon's Top 100!",
  },
  {
    name: "Dr. James Kwesi",
    role: "Personal Finance Expert",
    feedback:
      "Mark's expertise in author branding and book marketing was evident from day one. He helped position my book as a must-read, leading to it becoming an Amazon bestseller in the finance category.",
  },
];

const Testimonials = () => {
  return (
    <div className="py-16 px-4 sm:px-8 lg:px-16">
      <h1 className="text-center text-4xl font-bold text-gray-800 mb-12">
        Testimonials
      </h1>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg p-6 flex flex-col"
          >
            <h3 className="text-xl font-semibold text-gray-800 mb-1">
              {testimonial.name}
            </h3>
            <p className="text-sm text-gray-500 mb-4">{testimonial.role}</p>
            <p className="text-gray-700">{testimonial.feedback}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
