import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Adjust path if necessary
import { toast } from "react-toastify";

const SubService6 = () => {
  const dispatch = useDispatch();
  const [wordCount, setWordCount] = useState(""); // State to track word count input

  // Handle word count input
  const handleWordCountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setWordCount(value);
    }
  };

  // Calculate price based on word count
  const calculatePrice = (ratePerWord) => {
    return wordCount ? (wordCount * ratePerWord).toFixed(2) : 0;
  };

  // Add to cart with dynamic pricing
  const handleAddToCart = (id, name, price) => {
    if (wordCount === "" || wordCount <= 0) {
      toast.error("Please enter a valid word count.");
      return;
    }
    dispatch(addToCart({ id, name, price }));
    toast.success(`${name} added to the cart successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Header Section */}
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Ghostwriting: Bringing Your Vision to Life
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        Have a story to tell but struggling with the words? Our ghostwriting
        service is your solution.
      </p>

      {/* Word Count Input Section */}
      <div className="mb-10">
        <label
          htmlFor="wordCount"
          className="block text-lg font-semibold text-gray-800 mb-2"
        >
          Enter Word Count:
        </label>
        <input
          type="number"
          id="wordCount"
          value={wordCount}
          onChange={handleWordCountChange}
          className="w-full p-3 border border-gray-300 rounded-md"
          placeholder="Enter the number of words in your manuscript"
        />
      </div>

      {/* Ghostwriting Service Section */}
      <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">
          Ghostwriting
        </h2>
        <p className="text-xl font-semibold text-gray-800 mb-4">
          $0.21 per word
        </p>
        <ul className="list-disc pl-5 text-gray-600">
          <li>Our skilled ghostwriters capture your voice and vision.</li>
          <li>We create a compelling manuscript tailored to your needs.</li>
        </ul>
        <button
          onClick={() =>
            handleAddToCart(
              13,
              `Ghostwriting (${wordCount} words)`,
              calculatePrice(0.21)
            )
          }
          className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
        >
          Add to Cart (${calculatePrice(0.21)})
        </button>
      </div>

      {/* Additional Info Section */}
      <div className="mt-16">
        <p className="text-gray-600 text-lg leading-relaxed">
          Our skilled ghostwriters become your literary partners, capturing your
          voice and vision to create a compelling manuscript. We don't stop
          until you're completely satisfied, ensuring the final product is truly
          your story, expertly told.
        </p>
      </div>
    </div>
  );
};

export default SubService6;
