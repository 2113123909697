import React, { useState, useEffect } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { Link } from "react-router-dom";

const Slider = () => {
  const [textVisible, setTextVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setTextVisible(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const work = [
    {
      id: 1,
      image:
        "https://images.theconversation.com/files/45159/original/rptgtpxd-1396254731.jpg?ixlib=rb-4.1.0&q=45&auto=format&w=1356&h=668&fit=crop",
      title: "Launching Your Literary Success",
      headline: "Bestseller Service",
      description:
        "In today's competitive publishing landscape, achieving bestseller status is a game-changer for authors, speakers, and thought leaders. Our comprehensive Bestseller Service is designed to not only propel your book to the top of the charts but also to establish a strong foundation for your long-term success as an author..",
      buttonText: "Get Service",
      buttonLink: "/services",
    },
    {
      id: 2,
      image:
        "https://miro.medium.com/v2/resize:fit:5120/1*42ebJizcUtZBNIZPmmMZ5Q.jpeg",
      title: "Make a Lasting First Impression",
      headline: "Cover Design",
      description:
        "In the world of books, judging by the cover isn't just allowed—it's expected. Our professional cover design service ensures your book stands out in a crowded marketplace.",
      buttonText: "Get Service",
      buttonLink: "/services",
    },
  ];

  const handleSlideChange = () => {
    setTextVisible(false);
    setTimeout(() => setTextVisible(true), 500);
  };

  return (
    <div className="relative">
      <Swiper
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
          EffectFade,
        ]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        spaceBetween={0}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 1 },
          1024: { slidesPerView: 1 },
        }}
        onSlideChange={handleSlideChange}
      >
        {work.map((item) => (
          <SwiperSlide key={item.id} className="relative">
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-[70vh] object-cover"
            />
            <div
              className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000 ${
                textVisible ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className="bg-gradient-to-r from-[#000000a6] to-[#0000004d] text-white p-6 rounded-lg shadow-lg max-w-md w-full text-center transform transition-all duration-500 ease-in-out hover:scale-105">
                <p className="text-[#efcc41] text-xs font-semibold uppercase mb-2">
                  {item.title}
                </p>
                <h2 className="text-white text-2xl font-bold mb-3">
                  {item.headline}
                </h2>
                <p className="text-white text-sm mb-4">{item.description}</p>
                {item.buttonText && (
                  <Link
                    to={item.buttonLink || "#"}
                    className="bg-[#efcc41] text-[#62000f] px-5 py-2 rounded-full font-semibold hover:bg-[#ffc107] transition duration-300"
                  >
                    {item.buttonText}
                  </Link>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
