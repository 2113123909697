import React from "react";
import { useNavigate } from "react-router-dom";

const Cancle = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-semibold text-center mb-10 text-red-500">
        Payment Canceled
      </h1>
      <div className="text-center">
        <p className="text-lg text-gray-700">
          Sorry, your payment was canceled.
        </p>
        <button
          className="mt-4 px-6 py-2 bg-teal-500 text-white font-semibold rounded-md"
          onClick={() => navigate("/")}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default Cancle;
