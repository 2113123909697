import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import About from "./page/About";
import Faq from "./page/Faq";
import Privacy from "./page/Privcy";
import Testimonials from "./page/Testimonials";
import Resources from "./page/Resources";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ServicePage from "./page/Service";
import SubService1 from "./page/SubService1";
import Cart from "./page/Cart";
import SubService2 from "./page/SubService2";
import SubService3 from "./page/SubService3";
import SubService4 from "./page/SubService4";
import SubService5 from "./page/SubService5";
import SubService6 from "./page/SubService6";
import SubService7 from "./page/SubService7";
import SubService8 from "./page/SubService8";
import SubService9 from "./page/SubService9";
import Success from "./page/Success";
import Cancle from "./page/Cancle";
import MyOrder from "./page/MyOrder";
const App = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/resource" element={<Resources />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/services/1" element={<SubService1 />} />
        <Route path="/services/2" element={<SubService2 />} />
        <Route path="/services/3" element={<SubService3 />} />
        <Route path="/services/4" element={<SubService4 />} />
        <Route path="/services/5" element={<SubService5 />} />
        <Route path="/services/6" element={<SubService6 />} />
        <Route path="/services/7" element={<SubService7 />} />
        <Route path="/services/8" element={<SubService8 />} />
        <Route path="/services/9" element={<SubService9 />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancle />} />
        <Route path="/orders" element={<MyOrder />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
