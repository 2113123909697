import React from "react";
import { Slide } from "react-toastify";
import Slider from "../components/Slider";

const Home = () => {
  return (
    <div>
      <Slider />
      <div className="min-h-screen py-12">
        <div className="max-w-7xl mx-auto px-6 md:px-12">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="lg:text-5xl text-3xl font-extrabold text-indigo-700">
              BookBoost: Elevating Authors to Bestseller Status
            </h1>
            <p className="mt-4 text-lg text-gray-600">
              Your strategic partner in transforming your literary dreams into
              reality.
            </p>
          </div>

          {/* Cards Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Card 1 */}
            <div className="bg-white shadow-lg rounded-2xl overflow-hidden transition-transform transform hover:-translate-y-3 hover:shadow-2xl">
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Our Mission: Amplifying Voices, Expanding Reach
                </h2>
                <p className="text-gray-600 text-lg leading-relaxed">
                  At{" "}
                  <span className="font-bold text-indigo-600">BookBoost</span>,
                  we're on a mission to help authors, speakers, coaches, and
                  thought leaders achieve the recognition and success they
                  deserve. We tailor our services to elevate your work and
                  establish your authority.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white shadow-lg rounded-2xl overflow-hidden transition-transform transform hover:-translate-y-3 hover:shadow-2xl">
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Guaranteed Bestseller Status
                </h2>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Our Bestseller Service packages, including{" "}
                  <span className="font-bold text-indigo-600">
                    Bestseller Gold
                  </span>
                  and{" "}
                  <span className="font-bold text-indigo-600">
                    Bestseller Platinum
                  </span>
                  , ensure your book reaches the right audience and secures its
                  spot on bestseller charts through distribution, optimization,
                  and strategic marketing.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-white shadow-lg rounded-2xl overflow-hidden transition-transform transform hover:-translate-y-3 hover:shadow-2xl">
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Crafting Impactful Author Brands
                </h2>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Your brand is the cornerstone of your literary career. Our{" "}
                  <span className="font-bold text-indigo-600">
                    Author Branding Package
                  </span>{" "}
                  includes website design, brand strategy, and more to ensure
                  your unique voice resonates with your audience and sets you
                  apart.
                </p>
              </div>
            </div>

            {/* Card 4 */}
            <div className="bg-white shadow-lg rounded-2xl overflow-hidden transition-transform transform hover:-translate-y-3 hover:shadow-2xl">
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Unlock Your Full Potential
                </h2>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Whether you're a first-time author or a seasoned professional,
                  <span className="font-bold text-indigo-600">
                    {" "}
                    BookBoost
                  </span>{" "}
                  guides you through every step of the process, offering
                  personalized support and proven strategies to help you achieve
                  your literary goals.
                </p>
              </div>
            </div>

            {/* Card 5 */}
            <div className="bg-white shadow-lg rounded-2xl overflow-hidden transition-transform transform hover:-translate-y-3 hover:shadow-2xl">
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Start Your Bestselling Journey Today
                </h2>
                <p className="text-gray-600 text-lg leading-relaxed">
                  Explore our services and let{" "}
                  <span className="font-bold text-indigo-600">BookBoost</span>
                  be the catalyst for your success. Contact us now to schedule a
                  consultation and reach new levels of recognition, influence,
                  and impact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
