const services = [
    {
        id: 1,
        title: "Bestseller Service: Launching Your Literary Success",
        description:
            "Our comprehensive Bestseller Service is designed to not only propel your book to the top of the charts but also to establish a strong foundation for your long-term success as an author.",
        packages: [
            {
                name: "Bestseller Gold",
                price: "$4,000",
                features: [
                    "Guaranteed minimum of 500 sold copies on Amazon",
                    "Amazon Bestseller guarantee",
                    "Professional marketing campaign",
                    "Expert guidance throughout the bestseller journey",
                    "Upload to all major self-publishing platforms",
                    "Professionally crafted book description",
                    "Algorithm-aligned strategy implementation",
                    "Complete campaign preparation and execution"
                ]
            },
            {
                name: "Bestseller Platinum",
                price: "$8,000",
                features: [
                    "All features of Bestseller Gold",
                    "Guaranteed minimum of 1,000 sold copies on both Amazon and Barnes & Noble",
                    "Dual Bestseller guarantee on Amazon and Barnes & Noble",
                    "Comprehensive review outreach with guaranteed reviews",
                    "Prestigious editorial review",
                    "Extended marketing campaign across multiple platforms",
                    "Personalized strategy sessions with industry experts"
                ]
            }
        ],
        strategyHighlights: [
            {
                title: "Multi-Platform Presence",
                detail: "Upload your book to all major self-publishing platforms for maximum reach and availability."
            },
            {
                title: "Optimized Discoverability",
                detail: "Craft compelling book descriptions and align strategies with algorithms to improve discoverability."
            },
            {
                title: "Holistic Campaign Management",
                detail: "Manage every aspect of the bestseller campaign from preparation to execution."
            },
            {
                title: "Sustained Momentum",
                detail: "Design campaigns for lasting interest and ongoing sales."
            },
            {
                title: "Industry Insights",
                detail: "Leverage deep understanding of publishing and market trends for a competitive edge."
            }
        ],
        impact: {
            title: "The Impact on Your Career",
            benefits: [
                "Enhanced Credibility: Elevate your professional standing with the 'bestseller' label.",
                "Media Opportunities: Gain increased visibility, leading to interviews, podcasts, and speaking engagements.",
                "Higher Earning Potential: Command higher speaking fees and consulting rates.",
                "Expanded Network: Connect with influential figures in your industry.",
                "Future Opportunities: Open doors for more publishing deals and collaborative projects."
            ]
        },
        risks: {
            title: "The Cost of Missed Opportunities",
            points: [
                "Limited visibility in a saturated market",
                "Difficulty attracting media attention and speaking opportunities",
                "Challenges in building a robust author platform",
                "Reduced leverage for future book deals or projects"
            ]
        },
        approach: {
            title: "Our Approach",
            detail:
                "Our Bestseller Service is about giving your book the launch it deserves through ethical, strategic, and proven methods. We leverage our industry expertise, marketing acumen, and technological know-how to ensure your book reaches its target audience effectively."
        },
        investmentMessage:
            "Investing in our Bestseller Service is about laying the groundwork for a thriving career as an author, speaker, or thought leader. In an era where authority and visibility are crucial, can you afford to let your valuable insights go unnoticed?"
    },
    {
        id: 2,
        title: "Cover Design: Make a Lasting First Impression",
        description:
            "In the world of books, judging by the cover isn't just allowed—it's expected. Our professional cover design service ensures your book stands out in a crowded marketplace.",
        packages: [
            {
                name: "Two Cover Formats",
                price: "$695",
                features: ["Choose from paperback, hardback, or e-book"]
            },
            {
                name: "All Three Cover Formats",
                price: "$895",
                features: ["Includes paperback, hardback, and e-book formats"]
            }
        ],
        additionalInfo:
            "Our designers blend artistry with marketing savvy to create covers that not only look stunning but also resonate with your target audience. Don't let your book's potential be hidden behind a lackluster cover—invest in a design that demands attention."
    },

    {
        id: 3,
        title: "Interior Formatting: Beauty Beyond the Cover",
        description:
            "A well-formatted interior is the mark of a truly professional publication. Our interior formatting service ensures your book looks as good on the inside as it does on the outside.",
        packages: [
            {
                name: "Formatting for both print and e-book",
                price: "$595",
                features: ["Includes 3 images"],
            },
            {
                name: "Additional Images",
                price: "$150",
                features: ["For every block of 10 extra images"],
            },
        ],
        additionalInfo:
            "We meticulously craft each page to enhance readability and reflect the tone of your work. From font selection to layout design, we ensure your book's interior is a seamless extension of your narrative.",
    },

    {
        id: 4,
        title: "Cover and Interior Combo: The Complete Package",
        description:
            "For authors seeking a cohesive, professional look throughout their book, our combo package offers unbeatable value.",
        packages: [
            {
                name: "Two cover formats and interior formatting",
                price: "$1,000",
                features: ["Includes 3 images"],
            },
            {
                name: "All three cover formats",
                price: "+$200",
                features: [],
            },
            {
                name: "Additional interior images",
                price: "$150",
                features: ["For every block of 10 extra images"],
            },
        ],
        additionalInfo:
            "This comprehensive package ensures your book presents a unified, professional appearance from cover to cover, giving you a competitive edge in the marketplace.",
    },

    {
        id: 5,
        title: "Editing: Polishing Your Prose to Perfection",
        description:
            "Our editing services cater to fiction, nonfiction, and academic works, ensuring your manuscript shines in its best light.",
        packages: [
            {
                name: "Manuscript Critique",
                price: "$495",
                features: [],
            },
            {
                name: "Line Editing",
                price: "$0.06 per word",
                features: [],
            },
            {
                name: "Developmental Editing",
                price: "$0.10 per word",
                features: [],
            },
        ],
        additionalInfo:
            "Our team of experienced editors doesn't just correct; they elevate. We work closely with you to refine your voice, strengthen your narrative, and ensure your message resonates with readers.",
    },
    {
        id: 6,
        title: "Ghostwriting: Bringing Your Vision to Life",
        description:
            "Have a story to tell but struggling with the words? Our ghostwriting service is your solution.",
        packages: [
            {
                name: "Ghostwriting",
                price: "$0.21 per word",
                features: [],
            },
        ],
        additionalInfo:
            "Our skilled ghostwriters become your literary partners, capturing your voice and vision to create a compelling manuscript. We don't stop until you're completely satisfied, ensuring the final product is truly your story, expertly told.",
    },
    {
        id: 7,
        title: "Book Launch: Your Launchpad to Literary Success",
        description:
            "Transform your book release into a significant event with our comprehensive launch packages. We've successfully launched hundreds of books, with a 100% bestseller success rate.",
        packages: [
            {
                name: "Book Launch Essential",
                price: "$10,995",
                features: [
                    "All services of the Bestseller Gold package",
                    "Social media management and campaign",
                    "3 media interview bookings",
                    "Press release",
                    "Direct email to 10,000 book buyers",
                    "2-month duration",
                ],
            },
            {
                name: "Book Launch Premium",
                price: "$18,000",
                features: [
                    "Bestseller Platinum package with 5,000 sold book target",
                    "Social media management and campaign",
                    "6 media interview bookings",
                    "Direct email to over 100,000 book buyers",
                    "Press release distribution to major media outlets",
                    "Customized author website",
                    "Virtual book tour",
                    "3-month duration",
                ],
            },
        ],
        additionalInfo:
            "Our Book Launch packages are designed to create a buzz around your book, leveraging our industry connections and marketing expertise to ensure your work reaches its target audience. We understand the publishing landscape and don't rest until we've achieved our goals.",
    },
    {
        id: 8,
        title: "Audiobook Production: Give Voice to Your Words",
        description:
            "In today's fast-paced world, audiobooks are more popular than ever. Our audiobook production service brings your story to life with professional narration and high-quality audio.",
        packages: [
            {
                name: "Professional narration and production",
                price: "$0.05 per recorded word (minimum $150)",
                features: [
                    "High-quality narration",
                    "State-of-the-art recording and editing",
                ],
            },
            {
                name: "Additional services",
                price: "Custom quote based on project requirements",
                features: [
                    "Sound effects",
                    "Multiple narrators",
                ],
            },
        ],
        additionalInfo:
            "We use only the highest-rated, industry-quality voice actors, offering a wide range of voices to suit every mood, genre, and effect. Our state-of-the-art recording and editing process ensures a polished, professional product that will captivate listeners.",
    },
    {
        id: 9,
        title: "Author Branding: The Foundation of a Thriving Literary Career",
        description:
            "In today's interconnected world, your author brand is more than just a marketing tool—it's the cornerstone of your professional identity. For aspiring career authors, speakers, coaches, and consultants, a well-crafted brand is not just beneficial; it's essential.",
        package: {
            name: "Author Branding Package",
            price: "$14,995 (payment plan available)",
            features: [
                "Professional website design",
                "Custom logo creation",
                "Brand color palette development",
                "Intensive brand training sessions",
                "Crafting your unique value proposition",
                "Brand story creation",
                "Comprehensive SWOT analysis",
                "Defining your brand voice",
                "Establishing your brand identity",
                "Articulating your brand promise",
                "Identifying core brand values",
                "Brand targeting strategies",
                "Brand positioning in the literary market",
            ],
        },
        additionalInfo:
            "A strong author brand sets you apart in a crowded marketplace. It's not just about selling books; it's about creating a lasting connection with your audience. Here's why it matters:\n\n"
            + "a. Credibility and Trust: A professional brand lends authority to your work. It shows that you take your craft seriously, which in turn encourages readers, clients, and event organizers to take you seriously.\n\n"
            + "b. Consistency Across Platforms: Whether someone encounters you through your books, social media, or at a speaking engagement, a cohesive brand ensures they have a consistent experience. This consistency builds recognition and loyalty.\n\n"
            + "c. Career Longevity: Your brand helps you weather the ups and downs of the publishing industry. It allows you to pivot between books, speaking engagements, and consulting work while maintaining a strong professional identity.\n\n"
            + "d. Diversified Income Streams: A strong brand opens doors beyond book sales. It positions you for speaking engagements, coaching opportunities, and consulting work—essential for building a sustainable career.\n\n"
            + "e. Audience Connection: Your brand helps you attract and retain your ideal readers or clients. It communicates your values and expertise, drawing in those who resonate with your message.\n\n"
            + "The Cost of Neglecting Your Brand:\n\n"
            + "Without a strong brand, authors and speakers often struggle to:\n"
            + "- Stand out in a saturated market\n"
            + "- Command higher speaking fees or consulting rates\n"
            + "- Attract media attention and opportunities\n"
            + "- Build a loyal following\n"
            + "- Transition between different aspects of their career (e.g., from author to speaker)\n\n"
            + "Many talented individuals fade into obscurity not because of a lack of skill, but because they failed to develop a memorable, professional brand.\n\n"
            + "Our Approach:\n\n"
            + "We don't just create a surface-level image. Our branding process delves deep into your unique strengths, vision, and values. We help you articulate what sets you apart and then build a brand that authentically represents you across all platforms.\n\n"
            + "The result? A complete brand book that serves as your roadmap to success. It's the same level of branding used by global thought leaders and bestselling authors who have built enduring careers.\n\n"
            + "Investing in your brand is investing in your future. It's about creating a foundation that will support you not just for your next book launch, but for years to come. In a world where first impressions are often digital and fleeting, can you afford not to put your best foot forward?\n\n"
            + "Let us help you build a brand that opens doors, creates opportunities, and sets the stage for a thriving, multi-faceted career in the world of books and beyond.",
    },
];

export const mainservice = [
    {
        id: 1,
        title: "Bestseller Service: Launching Your Literary Success",
        description:
            "Our comprehensive Bestseller Service is designed to not only propel your book to the top of the charts but also to establish a strong foundation for your long-term success as an author."
    },
    {
        id: 2,
        title: "Cover Design: Make a Lasting First Impression",
        description:
            "In the world of books, judging by the cover isn't just allowed—it's expected. Our professional cover design service ensures your book stands out in a crowded marketplace."
    },
    {
        id: 3,
        title: "Interior Formatting: Beauty Beyond the Cover",
        description:
            "A well-formatted interior is the mark of a truly professional publication. Our interior formatting service ensures your book looks as good on the inside as it does on the outside."
    },
    {
        id: 4,
        title: "Cover and Interior Combo: The Complete Package",
        description:
            "For authors seeking a cohesive, professional look throughout their book, our combo package offers unbeatable value."
    },
    {
        id: 5,
        title: "Editing: Polishing Your Prose to Perfection",
        description:
            "Our editing services cater to fiction, nonfiction, and academic works, ensuring your manuscript shines in its best light."
    },
    {
        id: 6,
        title: "Ghostwriting: Bringing Your Vision to Life",
        description:
            "Have a story to tell but struggling with the words? Our ghostwriting service is your solution."
    },
    {
        id: 7,
        title: "Book Launch: Your Launchpad to Literary Success",
        description:
            "Transform your book release into a significant event with our comprehensive launch packages. We've successfully launched hundreds of books, with a 100% bestseller success rate."
    },
    {
        id: 8,
        title: "Audiobook Production: Give Voice to Your Words",
        description:
            "In today's fast-paced world, audiobooks are more popular than ever. Our audiobook production service brings your story to life with professional narration and high-quality audio."
    },
    {
        id: 9,
        title: "Author Branding: The Foundation of a Thriving Literary Career",
        description:
            "In today's interconnected world, your author brand is more than just a marketing tool—it's the cornerstone of your professional identity. For aspiring career authors, speakers, coaches, and consultants, a well-crafted brand is not just beneficial; it's essential."
    }
]