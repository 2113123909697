import React from "react";

const About = () => {
  return (
    <div className=" min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-6 md:px-12">
        {/* Section Title */}
        <div className="text-center mb-12">
          <h1 className="text-5xl font-extrabold text-indigo-700">ABOUT US</h1>
          <p className="mt-4 text-lg text-gray-600">
            Discover who we are and what drives our passion for excellence.
          </p>
        </div>

        {/* Main Content Card */}
        <div className="bg-white shadow-xl rounded-2xl overflow-hidden">
          <div className="p-8 md:p-12">
            <h2 className="text-4xl font-bold text-gray-800 mb-6 leading-tight">
              Crafting Bestsellers with Unparalleled Expertise
            </h2>

            <p className="text-gray-600 text-lg leading-relaxed mb-8">
              At <span className="text-indigo-600 font-bold">BookBoost</span>,
              we are more than just a service provider – we are a team of
              seasoned publishing professionals dedicated to empowering authors,
              speakers, and thought leaders like you. With over 100 years of
              combined experience working at the heart of the industry, we've
              honed our skills at the highest levels, from editing and research
              to successfully launching our own publishing ventures.
            </p>

            <p className="text-gray-600 text-lg leading-relaxed mb-8">
              Our origins trace back to the hallowed halls of the Big Five
              publishing houses, where we gained invaluable insights into the
              ever-evolving landscape of book publishing. However, as the
              industry rapidly shifted, we recognized the need for a more agile,
              author-centric approach.{" "}
              <span className="font-bold text-indigo-600">BookBoost</span> is
              our response – a new, innovative project fueled by our deep
              understanding of the current market and a relentless drive to help
              our clients achieve the success they deserve.
            </p>

            <p className="text-gray-600 text-lg leading-relaxed mb-8">
              In a world where over 11,000 books are published globally each
              day, standing out has become increasingly challenging. But with
              our proven track record, strategic mindset, and unwavering
              commitment to results, we're uniquely positioned to guide you
              through the complexities of modern publishing. We don't just offer
              services – we forge partnerships, leveraging our expertise to
              amplify your voice and propel your work to the forefront of your
              industry.
            </p>

            <p className="text-gray-600 text-lg leading-relaxed">
              Our confidence in our abilities is reflected in our bold
              guarantee:{" "}
              <span className="font-bold text-indigo-600">
                if we fail to deliver on our promises, you don't pay.
              </span>{" "}
              With <span className="font-bold text-indigo-600">BookBoost</span>,
              you're not just investing in a service – you're investing in a
              team of passionate, experienced professionals who will stop at
              nothing to transform your literary dreams into bestselling
              realities.
            </p>
          </div>

          {/* Call-to-Action Section */}
          <div className="bg-indigo-600 text-white py-8 px-6 md:px-12 text-center">
            <h3 className="text-2xl font-semibold mb-4">
              Ready to bring your book to life?
            </h3>
            <p className="text-lg mb-6">
              Partner with us and turn your ideas into a bestseller!
            </p>
            <button className="px-8 py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-md hover:bg-gray-200 transition-all">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
