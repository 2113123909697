import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Adjust the path if necessary
import { toast } from "react-toastify";

const SubService7 = () => {
  const dispatch = useDispatch();

  // Add to Cart Handler
  const handleAddToCart = (id, name, price) => {
    dispatch(addToCart({ id, name, price }));
    toast.success(`${name} added to the cart successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Header Section */}
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Book Launch: Your Launchpad to Literary Success
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        Transform your book release into a significant event with our
        comprehensive launch packages. We've successfully launched hundreds of
        books, with a 100% bestseller success rate.
      </p>

      {/* Packages Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {/* Book Launch Essential */}
        <div className="bg-yellow-400 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Book Launch Essential
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$10,995</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>All services of the Bestseller Gold package</li>
            <li>Social media management and campaign</li>
            <li>3 media interview bookings</li>
            <li>Press release</li>
            <li>Direct email to 10,000 book buyers</li>
            <li>2-month duration</li>
          </ul>
          <button
            onClick={() => handleAddToCart(14, "Book Launch Essential", 10995)}
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart
          </button>
        </div>

        {/* Book Launch Premium */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Book Launch Premium
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$18,000</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Bestseller Platinum package with 5,000 sold book target</li>
            <li>Social media management and campaign</li>
            <li>6 media interview bookings</li>
            <li>Direct email to over 100,000 book buyers</li>
            <li>Press release distribution to major media outlets</li>
            <li>Customized author website</li>
            <li>Virtual book tour</li>
            <li>3-month duration</li>
          </ul>
          <button
            onClick={() => handleAddToCart(15, "Book Launch Premium", 18000)}
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart
          </button>
        </div>
      </div>

      {/* Additional Info Section */}
      <div className="mt-16">
        <p className="text-gray-600 text-lg leading-relaxed">
          Our Book Launch packages are designed to create a buzz around your
          book, leveraging our industry connections and marketing expertise to
          ensure your work reaches its target audience. We understand the
          publishing landscape and don't rest until we've achieved our goals.
        </p>
      </div>
    </div>
  );
};

export default SubService7;
