import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Make sure the correct path is used
import { toast } from "react-toastify";

const SubService1 = () => {
  const dispatch = useDispatch();

  const handleAddToCart = (id, name, price) => {
    dispatch(addToCart({ id, name, price }));
    toast.success("Service added to the cart successfully");
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Bestseller Service: Launching Your Literary Success
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        In today's competitive publishing landscape, achieving bestseller status
        is a game-changer for authors, speakers, and thought leaders. Our
        comprehensive Bestseller Service is designed to not only propel your
        book to the top of the charts but also to establish a strong foundation
        for your long-term success as an author.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8">
        {/* Bestseller Gold Plan */}
        <div className="bg-yellow-400 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Bestseller Gold
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$4,000</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Guaranteed minimum of 500 sold copies on Amazon</li>
            <li>Amazon Bestseller guarantee</li>
            <li>Professional marketing campaign</li>
            <li>Expert guidance throughout the bestseller journey</li>
            <li>Upload to all major self-publishing platforms</li>
            <li>Professionally crafted book description</li>
            <li>Algorithm-aligned strategy implementation</li>
            <li>Complete campaign preparation and execution</li>
          </ul>
          <button
            onClick={() => handleAddToCart(1, "Bestseller Gold", 4000)}
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to cart
          </button>
        </div>

        {/* Bestseller Platinum Plan */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Bestseller Platinum
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$8,000</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>
              Guaranteed minimum of 1,000 sold copies on Amazon and Barnes &
              Noble
            </li>
            <li>Dual Bestseller guarantee on Amazon and Barnes & Noble</li>
            <li>Comprehensive review outreach with guaranteed reviews</li>
            <li>Prestigious editorial review</li>
            <li>Extended marketing campaign across multiple platforms</li>
            <li>Personalized strategy sessions with industry experts</li>
          </ul>
          <button
            onClick={() => handleAddToCart(2, "Bestseller Platinum", 8000)}
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to cart
          </button>
        </div>
      </div>

      {/* The Power of a Strategic Bestseller Campaign */}
      <div className="mt-16">
        <h3 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          The Power of a Strategic Bestseller Campaign
        </h3>
        <p className="text-gray-600 text-lg">
          Our Bestseller Service goes beyond simply boosting sales numbers. It's
          a carefully orchestrated strategy designed to maximize your book's
          visibility, credibility, and long-term success.
        </p>
        <ul className="list-disc pl-5 text-gray-600 mt-4">
          <li>
            Multi-Platform Presence: We handle the complexities of uploading
            your book to all major self-publishing platforms, ensuring maximum
            reach and availability.
          </li>
          <li>
            Optimized Discoverability: Our team crafts a compelling book
            description and implements strategies aligned with current
            algorithms, significantly improving your book's discoverability on
            online marketplaces.
          </li>
          <li>
            Holistic Campaign Management: From preparation to execution, we
            manage every aspect of your bestseller campaign, allowing you to
            focus on what you do best – writing and sharing your expertise.
          </li>
          <li>
            Sustained Momentum: Our campaigns are designed not just for a
            short-term sales spike, but to create lasting interest and ongoing
            sales, setting the stage for long-term success.
          </li>
          <li>
            Industry Insights: Benefit from our deep understanding of the
            publishing industry and current market trends, giving your book a
            competitive edge.
          </li>
        </ul>
      </div>

      {/* The Impact on Your Career */}
      <div className="mt-16">
        <h3 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          The Impact on Your Career
        </h3>
        <p className="text-gray-600 text-lg">
          Achieving bestseller status through our strategic approach can be
          transformative, with enhanced credibility, media opportunities, higher
          earning potential, expanded networks, and more.
        </p>
        <ul className="list-disc pl-5 text-gray-600 mt-4">
          <li>
            Enhanced Credibility: The "bestseller" label instantly elevates your
            professional standing.
          </li>
          <li>
            Media Opportunities: Increased visibility often leads to interviews,
            podcast appearances, and speaking engagements.
          </li>
          <li>
            Higher Earning Potential: Bestselling authors typically command
            higher speaking fees and consulting rates.
          </li>
          <li>
            Expanded Network: Our campaign can connect you with influential
            figures in your industry.
          </li>
          <li>
            Future Opportunities: Success with one book often leads to more
            publishing deals and collaborative projects.
          </li>
        </ul>
      </div>

      {/* The Cost of Missed Opportunities */}
      <div className="mt-16">
        <h3 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          The Cost of Missed Opportunities
        </h3>
        <ul className="list-disc pl-5 text-gray-600 mt-4">
          <li>Limited visibility in a saturated market.</li>
          <li>
            Difficulty attracting media attention and speaking opportunities.
          </li>
          <li>Challenges in building a robust author platform.</li>
          <li>Reduced leverage for future book deals or projects.</li>
        </ul>
      </div>

      {/* Our Approach */}
      <div className="mt-16">
        <h3 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          Our Approach
        </h3>
        <p className="text-gray-600 text-lg">
          We don't believe in shortcuts or manipulating the system. Our
          Bestseller Service is about giving your book the launch it deserves
          through ethical, strategic, and proven methods. We leverage our
          industry expertise, marketing acumen, and technological know-how to
          ensure your book reaches its target audience effectively.
        </p>
        <p className="text-gray-600 text-lg mt-4">
          Investing in our Bestseller Service is more than just promoting a
          single book – it's about laying the groundwork for a thriving career
          as an author, speaker, or thought leader. In an era where authority
          and visibility are crucial, can you afford to let your valuable
          insights go unnoticed?
        </p>
      </div>
    </div>
  );
};

export default SubService1;
