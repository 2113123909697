import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice"; // Adjust path if necessary
import { toast } from "react-toastify";

const SubService4 = () => {
  const dispatch = useDispatch();

  // Add to Cart Function
  const handleAddToCart = (id, name, price) => {
    dispatch(addToCart({ id, name, price }));
    toast.success(`${name} added to the cart successfully`);
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Header Section */}
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">
        Cover and Interior Combo: The Complete Package
      </h1>
      <p className="text-gray-600 text-lg text-center mb-10">
        For authors seeking a cohesive, professional look throughout their book,
        our combo package offers unbeatable value.
      </p>

      {/* Service Plans Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {/* Two Cover Formats and Interior Formatting Plan */}
        <div className="bg-yellow-400 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Two Cover Formats + Interior Formatting
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">$1,000</p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Includes 3 interior images</li>
          </ul>
          <button
            aria-label="Add Two Cover Formats + Interior Formatting to cart"
            onClick={() =>
              handleAddToCart(
                7,
                "Two Cover Formats + Interior Formatting",
                1000
              )
            }
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart
          </button>
        </div>

        {/* All Three Cover Formats */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            All Three Cover Formats
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">
            Add $200 to Base Package
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>All three formats: Paperback, Hardback, and E-Book</li>
          </ul>
          <button
            aria-label="Add All Three Cover Formats to cart"
            onClick={() => handleAddToCart(8, "All Three Cover Formats", 200)}
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart
          </button>
        </div>

        {/* Additional Interior Images */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Additional Interior Images
          </h2>
          <p className="text-xl font-semibold text-gray-800 mb-4">
            $150 (for 10 extra images)
          </p>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Blocks of 10 additional interior images</li>
          </ul>
          <button
            aria-label="Add Additional Interior Images to cart"
            onClick={() =>
              handleAddToCart(9, "Additional Interior Images (10 extra)", 150)
            }
            className="mt-6 p-4 bg-teal-500 text-white rounded-md text-center font-semibold"
          >
            Add to Cart
          </button>
        </div>
      </div>

      {/* Additional Info Section */}
      <div className="mt-16">
        <p className="text-gray-600 text-lg leading-relaxed">
          This comprehensive package ensures your book presents a unified,
          professional appearance from cover to cover, giving you a competitive
          edge in the marketplace.
        </p>
      </div>
    </div>
  );
};

export default SubService4;
